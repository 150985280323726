const { WEB_CLIENT } = typeof gbCommonInfo === 'object' ? gbCommonInfo : {}
const singleCountryShein = {
  us: {
    id: '226',
    country: 'United States',
    phone_code: '+1',
    value: 'US'
  },
  uses: {
    id: '226',
    country: 'United States',
    phone_code: '+1',
    value: 'US'
  },
  uk: {
    id: '225',
    country: 'United Kingdom',
    phone_code: '+44',
    value: 'GB'
  },
  ca: {
    id: '38',
    country: 'Canada',
    phone_code: '+1',
    value: 'CA'
  },
  cafr: {
    id: '38',
    country: 'Canada',
    phone_code: '+1',
    value: 'CA'
  },
  in: {
    id: '100',
    country: 'India',
    phone_code: '+91',
    value: 'IN'
  },
  se: {
    id: '206',
    country: 'Sweden',
    phone_code: '+46',
    value: 'SE'
  },
  sesv: {
    id: '206',
    country: 'Sweden',
    phone_code: '+46',
    value: 'SE'
  },
  tw: {
    id: '209',
    country: 'Taiwan',
    phone_code: '+886',
    value: 'TW'
  },
  mx: {
    id: '137',
    country: 'Mexico',
    phone_code: '+52',
    value: 'MX'
  },
  il: {
    id: '105',
    country: 'ישראל',
    phone_code: '+972',
    value: 'IL'
  },
  ilen: {
    id: '105',
    country: 'Israel',
    phone_code: '+972',
    value: 'IL'
  },
  th: {
    id: '212',
    country: 'Thailand',
    phone_code: '+66',
    value: 'TH'
  },
  then: {
    id: '212',
    country: 'Thailand',
    phone_code: '+66',
    value: 'TH'
  },
  nl: {
    id: '150',
    country: 'Netherlands',
    phone_code: '+31',
    value: 'NL'
  },
  hk: {
    id: '97',
    country: 'Hong Kong',
    phone_code: '+852',
    value: 'HK'
  },
  tr: {
    id: '218',
    country: 'Turkey',
    phone_code: '+90',
    value: 'TR'
  },
  br: {
    id: '30',
    country: 'Brazil',
    phone_code: '+55',
    value: 'BR'
  },
  vn: {
    id: '233',
    country: 'Vietnam',
    phone_code: '+84',
    value: 'VN'
  },
  cl: {
    id: '43',
    country: 'Chile',
    phone_code: '+56',
    value: 'CL'
  },
  arg: {
    id: '10',
    country: 'Argentina',
    phone_code: '+54',
    value: 'AR'
  },
  za: {
    id: '196',
    country: 'South Africa',
    phone_code: '+27',
    value: 'ZA'
  },
  pl: {
    id: '172',
    country: 'Poland',
    phone_code: '+48',
    value: 'PL'
  },
  nz: {
    id: '153',
    country: 'New Zealand',
    phone_code: '+64',
    value: 'NZ'
  },
  es: {
    id: '198',
    country: 'Spain',
    phone_code: '+34',
    value: 'ES'
  },
  my: {
    id: '128',
    country: 'Malaysia',
    phone_code: '+60',
    value: 'MY',
  },
}
const singleCountryRomwe = {
  us: {
    id: '226',
    country: 'United States',
    phone_code: '+1',
    value: 'US'
  },
  uk: {
    id: '225',
    country: 'United Kingdom',
    phone_code: '+44',
    value: 'GB'
  },
  ca: {
    id: '38',
    country: 'Canada',
    phone_code: '+1',
    value: 'CA'
  },
  cafr: {
    id: '38',
    country: 'Canada',
    phone_code: '+1',
    value: 'CA'
  },
  in: {
    id: '100',
    country: 'India',
    phone_code: '+91',
    value: 'IN'
  }
}
export const indexConfig = {
  singleCountry: WEB_CLIENT === 'romwe' ? singleCountryRomwe : singleCountryShein,
  hasStandbyTel: ['113', '224', '186', '175', '162', '17', '100', '233', '209', '212'],
}

export const initAddressLanguage = data => {
  const { lang, langPath, PUBLIC_CDN } = gbCommonInfo
  return {
    postcode: {
      14: data.SHEIN_KEY_PWA_15899,
      21: data.SHEIN_KEY_PWA_15899,
      33: data.SHEIN_KEY_PWA_15899,
      54: data.SHEIN_KEY_PWA_15866,
      57: data.SHEIN_KEY_PWA_15902,
      58: data.SHEIN_KEY_PWA_15899,
      68: data.SHEIN_KEY_PWA_15866,
      73: data.SHEIN_KEY_PWA_15866,
      74: data.SHEIN_KEY_PWA_15866,
      82: data.SHEIN_KEY_PWA_15866,
      85: data.SHEIN_KEY_PWA_15902,
      98: data.SHEIN_KEY_PWA_15899,
      106: data.SHEIN_KEY_PWA_15866,
      116: data.SHEIN_KEY_PWA_15899,
      123: data.SHEIN_KEY_PWA_15899,
      150: data.SHEIN_KEY_PWA_15922,
      161: data.SHEIN_KEY_PWA_15899,
      172: data.SHEIN_KEY_PWA_15904,
      173: data.SHEIN_KEY_PWA_15906,
      198: data.SHEIN_KEY_PWA_15989,
      206: data.SHEIN_KEY_PWA_15866,
      207: data.SHEIN_KEY_PWA_15899,
      225: data.SHEIN_KEY_PWA_15945,
      122: data.SHEIN_KEY_PWA_15866,
      193: data.SHEIN_KEY_PWA_16502.replace('6', '4'),
      192: data.SHEIN_KEY_PWA_15902,
      226: data.SHEIN_KEY_PWA_15932,
      13: data.SHEIN_KEY_PWA_15899,
      178: data.SHEIN_KEY_PWA_15927,
      100: data.SHEIN_KEY_PWA_16502,
      105: data.SHEIN_KEY_PWA_15935,
      38: data.SHEIN_KEY_PWA_15918,
      137: data.SHEIN_KEY_PWA_15866,
      176: data.SHEIN_KEY_PWA_15866,
      209: data.SHEIN_KEY_PWA_15866,
      101: data.SHEIN_KEY_PWA_15866,
      218: data.SHEIN_KEY_PWA_15866,
      30: data.SHEIN_KEY_PWA_15913,
      212: data.SHEIN_KEY_PWA_15866,
      233: data.SHEIN_KEY_PWA_16465,
      186: data.SHEIN_KEY_PWA_17195,
      139: data.SHEIN_KEY_PWA_16502.replace('6', '4'),
      36: data.SHEIN_KEY_PWA_16502,
      110: data.SHEIN_KEY_PWA_16502,
      71: data.SHEIN_KEY_PWA_16502.replace('6', '3'),
      170: data.SHEIN_KEY_PWA_16502.replace('6', '4'),
      15: data.SHEIN_KEY_PWA_16502.replace('6', '4'),
      108: data.SHEIN_KEY_PWA_17733,
      191: data.SHEIN_KEY_PWA_16502,
      47: data.SHEIN_KEY_PWA_16502,
      10: data.SHEIN_KEY_PWA_16502.replace('6', '4'),
      166: data.SHEIN_KEY_PWA_17926,
      26: data.SHEIN_KEY_PWA_17926,
      216: data.SHEIN_KEY_PWA_17926,
      104: data.SHEIN_KEY_PWA_17746,
      196: data.SHEIN_KEY_PWA_15899,
      144: data.SHEIN_KEY_PWA_15866,
      117: data.SHEIN_KEY_PWA_17930,
      109: data.SHEIN_KEY_PWA_15866,
      64: data.SHEIN_KEY_PWA_15866,
      81: data.SHEIN_KEY_PWA_16502.replace('6', '4'),
      40: data.SHEIN_KEY_PWA_17926,
      89: data.SHEIN_KEY_PWA_15866,
      24: data.SHEIN_KEY_PWA_17926,
      229: data.SHEIN_KEY_PWA_16502,
      235: data.SHEIN_KEY_PWA_15866,
      63: data.SHEIN_KEY_PWA_16502,
      197: data.SHEIN_KEY_PWA_15866,
      69: data.SHEIN_KEY_PWA_16502.replace('6', '4'),
      107: data.SHEIN_KEY_PWA_17926,
      153: data.SHEIN_KEY_PWA_16502.replace('6', '4'),
      43: data.SHEIN_KEY_PWA_15935,
    },
    label: {
      fname: data.SHEIN_KEY_PWA_15083,
      lname: data.SHEIN_KEY_PWA_15084,
      address1: data.SHEIN_KEY_PWA_15086,
      address2: data.SHEIN_KEY_PWA_15087,
      address1_de: data.SHEIN_KEY_PWA_14850,
      address1_th: data.SHEIN_KEY_PWA_18421,
      address2_de: data.SHEIN_KEY_PWA_14851,
      address2_br: data.SHEIN_KEY_PWA_17211,
      address2_empty: data.SHEIN_KEY_PWA_16160,
      address2_th: data.SHEIN_KEY_PWA_18435,
      address2_pl: data.SHEIN_KEY_PWA_15612,
      postcode: data.SHEIN_KEY_PWA_15079,
      country: data.SHEIN_KEY_PWA_16020,
      region: data.SHEIN_KEY_PWA_16496,
      street: data.SHEIN_KEY_PWA_15077,
      street_address: data.SHEIN_KEY_PWA_15985,
      state: data.SHEIN_KEY_PWA_15076,
      city: data.SHEIN_KEY_PWA_15075,
      city_suburb: data.SHEIN_KEY_PWA_18093,
      city_municipality: data.SHEIN_KEY_PWA_18436,
      suburb: data.SHEIN_KEY_PWA_18119,
      district: data.SHEIN_KEY_PWA_15078,
      district_qa: data.SHEIN_KEY_PWA_17482,
      district_ph: data.SHEIN_KEY_PWA_18423,
      tel: data.SHEIN_KEY_PWA_14978,
      standby_tel: data.SHEIN_KEY_PWA_16024,
      tax_number: data.SHEIN_KEY_PWA_15085,
      tax_number_cl: data.SHEIN_KEY_PWA_16727,
      tax_number_pe: data.SHEIN_KEY_PWA_17801,
      tax_number_bm: data.SHEIN_KEY_PWA_17001,
      tax_number_id: data.SHEIN_KEY_PWA_18737,
      t_vat_id: data.SHEIN_KEY_PWA_15120,
      select_country: data.SHEIN_KEY_PWA_16021,
      select_country_shield: data.SHEIN_KEY_PWA_17157,
      select_country_shield_pr: data.SHEIN_KEY_PWA_17356,
      select_state: data.SHEIN_KEY_PWA_15286,
      select_city: data.SHEIN_KEY_PWA_16022,
      select_city_suburb: data.SHEIN_KEY_PWA_18118,
      select_city_municipality: data.SHEIN_KEY_PWA_18545,
      select_barangay: data.SHEIN_KEY_PWA_18546,
      select_suburb: data.SHEIN_KEY_PWA_18085,
      select_district: data.SHEIN_KEY_PWA_16023,
      choose_state: data.SHEIN_KEY_PWA_15804,
      choose_city: data.SHEIN_KEY_PWA_15805,
      choose_suburb: data.SHEIN_KEY_PWA_18121,
      choose_city_suburb: data.SHEIN_KEY_PWA_18117,
      choose_city_municipality: data.SHEIN_KEY_PWA_18543,
      choose_district: data.SHEIN_KEY_PWA_15806,
      choose_barangay: data.SHEIN_KEY_PWA_18544,
      choose_postcode: data.SHEIN_KEY_PWA_16078,
      fullname: data.SHEIN_KEY_PWA_15122,
      fatherName: data.SHEIN_KEY_PWA_15131,
      englishName: data.SHEIN_KEY_PWA_15946,
      street_in: data.SHEIN_KEY_PWA_15123,
      address1_in: data.SHEIN_KEY_PWA_15124,
      address1_vi_text: data.SHEIN_KEY_PWA_16011,
      address2_in: data.SHEIN_KEY_PWA_16796,
      address2_in_text: data.SHEIN_KEY_PWA_15125,
      street_address_text: data.SHEIN_KEY_PWA_15986,
      addresstype: data.SHEIN_KEY_PWA_15126,
      home: data.SHEIN_KEY_PWA_15127,
      work: data.SHEIN_KEY_PWA_15128,
      middleName: data.SHEIN_KEY_PWA_15154,
      national_id: data.SHEIN_KEY_PWA_15155,
      address1_qa: data.SHEIN_KEY_PWA_17486,
      address2_qa: data.SHEIN_KEY_PWA_17488,
      street_qa: data.SHEIN_KEY_PWA_17484,
      email: data.SHEIN_KEY_PWA_18384,
      confirm: data.SHEIN_KEY_PWA_15312,
  
      address1_tw: '路名/段名',
      address2_tw: '巷/弄/號/樓',
      pl_address1: data.SHEIN_KEY_PWA_15132,
      pl_address1_my: data.SHEIN_KEY_PWA_19246,
      pl_address2: data.SHEIN_KEY_PWA_15133,
      pl_address2_nl: data.SHEIN_KEY_PWA_15923,
      pl_address1_us: data.SHEIN_KEY_PWA_15506,
      pl_address1_tw: '例如：忠孝東路五段',
      pl_address1_pl: data.SHEIN_KEY_PWA_14583,
      pl_address1_197: data.SHEIN_KEY_PWA_19900,
      pl_address2_tw: '例如：999巷99弄99號9樓之9',
      pl_address1_mx: data.SHEIN_KEY_PWA_16498,
      pl_address2_mx: data.SHEIN_KEY_PWA_16499,
      pl_address1_es: data.SHEIN_KEY_PWA_16500,
      pl_address1_ph: data.SHEIN_KEY_PWA_18424,
      pl_address2_es: data.SHEIN_KEY_PWA_16501,
      pl_address2_us: data.SHEIN_KEY_PWA_16503,
      pl_address1_in: data.SHEIN_KEY_PWA_16797,
      pl_address2_in: data.SHEIN_KEY_PWA_16798,
      pl_address2_br: data.SHEIN_KEY_PWA_18083,
      pl_address2_th: data.SHEIN_KEY_PWA_18422,
      pl_address2_ph: data.SHEIN_KEY_PWA_18426,
      pl_address2_pl: data.SHEIN_KEY_PWA_14582,
      pl_address2_197: data.SHEIN_KEY_PWA_19901,
          
      bubble_postcode_4: data.SHEIN_KEY_PWA_15898,
      bubble_postcode_5: data.SHEIN_KEY_PWA_15900,
      bubble_postcode_6: data.SHEIN_KEY_PWA_15926,
      bubble_postcode_7: data.SHEIN_KEY_PWA_15934,
      bubble_postcode_ca: data.SHEIN_KEY_PWA_15917,
      bubble_postcode_east: data.SHEIN_KEY_PWA_15953,
      bubble_postcode_br: data.SHEIN_KEY_PWA_15912,
      bubble_postcode_5_space: data.SHEIN_KEY_PWA_15901,
      bubble_postcode_5_line: data.SHEIN_KEY_PWA_15903,
      bubble_postcode_7_line: data.SHEIN_KEY_PWA_15905,
      bubble_postcode_vn: data.SHEIN_KEY_PWA_16471,
      bubble_postcode_lt: data.SHEIN_KEY_PWA_15907,
      bubble_postcode_si: data.SHEIN_KEY_PWA_15908,
      bubble_postcode_us: data.SHEIN_KEY_PWA_15931,
      bubble_postcode_uk: data.SHEIN_KEY_PWA_15944,
      bubble_postcode_nl: data.SHEIN_KEY_PWA_15921,
      bubble_postcode_in: data.SHEIN_KEY_PWA_16502,
      bubble_tax_number: data.SHEIN_KEY_PWA_16010,
      bubble_national_id: data.SHEIN_KEY_PWA_15956,
      bubble_national_id_jo: data.SHEIN_KEY_PWA_16718,
      bubble_national_id_il: data.SHEIN_KEY_PWA_17804,
      bubble_fullname: data.SHEIN_KEY_PWA_16158,
      bubble_tel_us: data.SHEIN_KEY_PWA_16157,
      bubble_name_tw: '請填寫真實姓名。',
      bubble_name_ar: data.SHEIN_KEY_PWA_17050, 
      bubble_tax_number_cl: data.SHEIN_KEY_PWA_17125,
      bubble_tax_number_id: data.SHEIN_KEY_PWA_18736,
      bubble_email: data.SHEIN_KEY_PWA_14641,
      common: data.SHEIN_KEY_PWA_16009,
      choose: data.SHEIN_KEY_PWA_16025,
      tel_text: data.SHEIN_KEY_PWA_16948,
      zone: data.SHEIN_KEY_PWA_17482,
      national_id_tr: data.SHEIN_KEY_PWA_17731,
      identity_doc_type: data.SHEIN_KEY_PWA_17776,
      national_id_tw: data.SHEIN_KEY_PWA_17824,
      certificate_type: data.SHEIN_KEY_PWA_18569,
      passport_number: data.SHEIN_KEY_PWA_16999,
      neighborhood: data.SHEIN_KEY_PWA_18549,
      national_id_196: data.SHEIN_KEY_PWA_19717,
      tax_number_63: data.SHEIN_KEY_PWA_19903,
      passport_number_197: data.SHEIN_KEY_PWA_19899,
      tax_number_197: data.SHEIN_KEY_PWA_20278,
      radio_label_197: data.SHEIN_KEY_PWA_19894

    },
    other: {
      public_cdn: PUBLIC_CDN,
      lang_path: langPath,
      lang: lang,
      text_submit_btn_save: data.SHEIN_KEY_PWA_15102,
      SHEIN_KEY_PWA_15157: data.SHEIN_KEY_PWA_15157,
      SHEIN_KEY_PWA_15158: data.SHEIN_KEY_PWA_15158,
      text_title_tw_tip: '應台灣海關實名認證要求，請務必填寫真實姓名。',
      optional: data.SHEIN_KEY_PWA_15101, /* 选填标志 */
      privacy_security: data.SHEIN_KEY_PWA_15088,
      make_default: data.SHEIN_KEY_PWA_15200,
      defalut_title: data.SHEIN_KEY_PWA_15081,
      billing_title: data.SHEIN_KEY_PWA_15090,
      cancel: data.SHEIN_KEY_PWA_15106,
      SHEIN_KEY_PWA_15719: data.SHEIN_KEY_PWA_15719,
      geo_yes: data.SHEIN_KEY_PWA_15706,
      geo_use_loc: data.SHEIN_KEY_PWA_16561,
      geo_allow: data.SHEIN_KEY_PWA_16543,
      geo_not_allow: data.SHEIN_KEY_PWA_16544,
      geo_not_support: data.SHEIN_KEY_PWA_16577,
      geo_fail: data.SHEIN_KEY_PWA_16576,
      geo_policy: data.SHEIN_KEY_PWA_16560,
      tax_number_tip1: data.SHEIN_KEY_PWA_16715,
      tax_number_tip2: data.SHEIN_KEY_PWA_16716,
      auto_update_text: data.SHEIN_KEY_PWA_17124,
      enter_address_manual: data.SHEIN_KEY_PWA_17334,
      find_address_tips: data.SHEIN_KEY_PWA_17333,
      enter_address_tips: data.SHEIN_KEY_PWA_17348,
      search_address_tips: data.SHEIN_KEY_PWA_17347,
      google_search_address_tips: data.SHEIN_KEY_PWA_14579,
      address_finder: data.SHEIN_KEY_PWA_17335,
      not_know_postcode_1: data.SHEIN_KEY_PWA_17491,
      not_know_postcode_2: data.SHEIN_KEY_PWA_17502,
      text_checkout_qa_tip: data.SHEIN_KEY_PWA_17490,
      text_zone: data.SHEIN_KEY_PWA_17492,
      text_building: data.SHEIN_KEY_PWA_16796,
      text_unit: data.SHEIN_KEY_PWA_15702,
      text_real_name: data.SHEIN_KEY_PWA_17730,
      management_office_collection: data.SHEIN_KEY_PWA_17662,
      management_office_collection_desc: data.SHEIN_KEY_PWA_17663,
      text_personal_info: data.SHEIN_KEY_PWA_17803,
      close: data.SHEIN_KEY_PWA_15567,
      clear: data.SHEIN_KEY_PWA_14885,
      not_support_modified: data.SHEIN_KEY_PWA_18259,
      text_checkout_qa_national_id_tips: data.SHEIN_KEY_PWA_18568,
      text_checkout_sa_national_id_tips: data.SHEIN_KEY_PWA_18567,
      text_checkout_qa_passport_tips: data.SHEIN_KEY_PWA_18566,
      text_checkout_sa_passport_tips: data.SHEIN_KEY_PWA_18565,
      not_find_address: data.SHEIN_KEY_PWA_18332,
      google_choose_address: data.SHEIN_KEY_PWA_19266,
      google_search_address: data.SHEIN_KEY_PWA_19267,
      picker_up_address: data.SHEIN_KEY_PWA_15879,
      tap_location: data.SHEIN_KEY_PWA_19268,
      confirm_location: data.SHEIN_KEY_PWA_19269,
      choose_address: data.SHEIN_KEY_PWA_19343,
      invalid_address: data.SHEIN_KEY_PWA_19339,
      SHEIN_KEY_PWA_19587: data.SHEIN_KEY_PWA_19587,
      SHEIN_KEY_PWA_19586: data.SHEIN_KEY_PWA_19586,
      supplement_id_number_title_za: data.SHEIN_KEY_PWA_20037,
      supplement_id_number_tip_za: data.SHEIN_KEY_PWA_20034,
      radio_yes: data.SHEIN_KEY_PWA_15252,
      radio_no: data.SHEIN_KEY_PWA_15253
    },
    tips: {
      er_blacklsit_1: data.SHEIN_KEY_PWA_15130,
      er_blacklsit_2: data.SHEIN_KEY_PWA_20965 || '',
      er_blacklsit_4: WEB_CLIENT === 'romwe' ? data.SHEIN_KEY_PWA_16835.replace('SHEIN', 'ROMWE') : data.SHEIN_KEY_PWA_16835,
      er_blacklsit_5: data.SHEIN_KEY_PWA_16795,
      er_blacklsit_6: data.SHEIN_KEY_PWA_17493,
      er_blacklsit_7: data['300510'],
      fname: data.SHEIN_KEY_PWA_15891,
      fname_1_10: '姓名必须是2-10个繁体中文或2-35个英文字母。',
      fname_2_30: data.SHEIN_KEY_PWA_15929,
      fname_4_35: data.SHEIN_KEY_PWA_15916,
      fname_4_35_mx: data.SHEIN_KEY_PWA_16278,
      fname_2_40_th: data.SHEIN_KEY_PWA_16001,
      fname_2_40_gr: data.SHEIN_KEY_PWA_15959,
      fname_2_32_sg: data.SHEIN_KEY_PWA_17739,
      fname_2_35_special: data.SHEIN_KEY_PWA_17734,
      fname_2_35_be: data.SHEIN_KEY_PWA_17747,
      lname: data.SHEIN_KEY_PWA_15892,
      lname_1_10: data.SHEIN_KEY_PWA_16799,
      lname_2_30: data.SHEIN_KEY_PWA_15930,
      lname_2_40_th: data.SHEIN_KEY_PWA_16002,
      lname_2_40_gr: data.SHEIN_KEY_PWA_15960,
      lname_35_specail: data.SHEIN_KEY_PWA_17736,
      lname_35_be: data.SHEIN_KEY_PWA_17748,
      lname_32_sg: data.SHEIN_KEY_PWA_17740,
      lname_exceed_40: data.SHEIN_KEY_PWA_17933,
      lname_exceed_40_gr: data.SHEIN_KEY_PWA_18540,
      middleName: data.SHEIN_KEY_PWA_15955,
      fatherName: data.SHEIN_KEY_PWA_16003,
      englishName: {
        ru: data.SHEIN_KEY_PWA_15947,
        sa: data.SHEIN_KEY_PWA_17500
      },
      state: data.SHEIN_KEY_PWA_15981,
      state_exceed: data.SHEIN_KEY_PWA_15894,
      state_85: data.SHEIN_KEY_PWA_15958,
      state_50_sg: data.SHEIN_KEY_PWA_17741,
      state_35_special: data.SHEIN_KEY_PWA_17929,
      state_exceed_40: data.SHEIN_KEY_PWA_17928,
      state_35_be: data.SHEIN_KEY_PWA_17749,
      city: data.SHEIN_KEY_PWA_15982,
      city_85: data.SHEIN_KEY_PWA_15984,
      city_225: data.SHEIN_KEY_PWA_15983,
      city_191: data.SHEIN_KEY_PWA_17742,
      city_2_35_special: data.SHEIN_KEY_PWA_17735,
      city_2_40: data.SHEIN_KEY_PWA_17927,
      city_35_be: data.SHEIN_KEY_PWA_17750,
      city_153: data.SHEIN_KEY_PWA_18086,
      city_13: data.SHEIN_KEY_PWA_18120,
      street: data.SHEIN_KEY_PWA_15950,
      street_exceed: data.SHEIN_KEY_PWA_15928,
      street_contain_house_no: data.SHEIN_KEY_PWA_17398,
      street_1_30: data.SHEIN_KEY_PWA_17485,
      street_number: data.SHEIN_KEY_PWA_17495,
      taxNumber: data.SHEIN_KEY_PWA_15914,
      taxNumber_valid: data.SHEIN_KEY_PWA_15915,
      taxNumber_valid_cl: data.SHEIN_KEY_PWA_16717,
      taxNumber_valid_bm: data.SHEIN_KEY_PWA_18090,
      taxNumber_valid_kr: data.SHEIN_KEY_PWA_14585,
      taxNumber_valid_id: data.SHEIN_KEY_PWA_18735,
      address1: data.SHEIN_KEY_PWA_15896,
      address1_po: data.SHEIN_KEY_PWA_15067,
      address1_5_35: data.SHEIN_KEY_PWA_15920,
      address1_2_30: data.SHEIN_KEY_PWA_16261,
      address1_5_100_th: data.SHEIN_KEY_PWA_15936,
      address1_5_100_gr: data.SHEIN_KEY_PWA_15961,
      address1_5_100_in: data.SHEIN_KEY_PWA_15990,
      address1_5_40: data.SHEIN_KEY_PWA_15924,
      address1_1_50: data.SHEIN_KEY_PWA_17487,
      address1_number: data.SHEIN_KEY_PWA_17496,
      address1_no_special_character: data.SHEIN_KEY_PWA_17664,
      address1_5_45_special: data.SHEIN_KEY_PWA_17737,
      address1_5_75_sg: data.SHEIN_KEY_PWA_17743,
      address1_special_ph: data.SHEIN_KEY_PWA_18425,
      address1_be: data.SHEIN_KEY_PWA_17932,
      address2: data.SHEIN_KEY_PWA_15897,
      address2_100_gr: data.SHEIN_KEY_PWA_15962,
      address2_100_in: data.SHEIN_KEY_PWA_15991,
      address2_90: data.SHEIN_KEY_PWA_15909,
      address2_30: data.SHEIN_KEY_PWA_15992,
      address2_100_th: data.SHEIN_KEY_PWA_15937,
      address2_1_20: data.SHEIN_KEY_PWA_15949,
      address2_74: data.SHEIN_KEY_PWA_16111,
      address2_82: data.SHEIN_KEY_PWA_16159,
      address2_35_it: data.SHEIN_KEY_PWA_16464,
      address2_35_house_no: data.SHEIN_KEY_PWA_18420,
      address2_exceed_50: data.SHEIN_KEY_PWA_17489,
      address2_45_special: data.SHEIN_KEY_PWA_17738,
      address2_39_pl: data.SHEIN_KEY_PWA_14584,
      address2_75_sg: data.SHEIN_KEY_PWA_17744,
      address2_number: data.SHEIN_KEY_PWA_18084,
      address_3en: data.SHEIN_KEY_PWA_16504,
      address_charater: data.SHEIN_KEY_PWA_15136,
      street_address_5_35: data.SHEIN_KEY_PWA_15988,
      street_address_5_120: data.SHEIN_KEY_PWA_15987,
      postcode: data.SHEIN_KEY_PWA_15895,
      tel: data.SHEIN_KEY_PWA_15893,
      tel_in: data.SHEIN_KEY_PWA_15925,
      tel_tw: data.SHEIN_KEY_PWA_15938,
      tel_uk: data.SHEIN_KEY_PWA_15943,
      tel_vi: data.SHEIN_KEY_PWA_15972,
      tel_th: data.SHEIN_KEY_PWA_14852,
      tel_th_zero: data.SHEIN_KEY_PWA_18541,
      tel_sv: data.SHEIN_KEY_PWA_15911,
      tel_br: data.SHEIN_KEY_PWA_17931,
      tel_hk: data.SHEIN_KEY_PWA_15942,
      tel_col: data.SHEIN_KEY_PWA_16008,
      tel_ar: data.SHEIN_KEY_PWA_15957,
      tel_kw: data.SHEIN_KEY_PWA_15954,
      tel_il: data.SHEIN_KEY_PWA_15933,
      tel_om: data.SHEIN_KEY_PWA_15951,
      tel_no_same: data.SHEIN_KEY_PWA_15952,
      tel_sg: data.SHEIN_KEY_PWA_17745,
      tel_ph: data.SHEIN_KEY_PWA_15505,
      tel_mx: data.SHEIN_KEY_PWA_16157,
      tel_5_25: data.SHEIN_KEY_PWA_15919,
      national_id: data.SHEIN_KEY_PWA_16473,
      national_id_jo: data.SHEIN_KEY_PWA_16719,
      national_id_il: data.SHEIN_KEY_PWA_17802,
      lname_exceed_35_ca: data.SHEIN_KEY_PWA_16258,
      lname_exceed_35_mx: data.SHEIN_KEY_PWA_16259,
      lname_exceed_30_de: data.SHEIN_KEY_PWA_16260,
      zone_1_50: data.SHEIN_KEY_PWA_17483,
      zone_number: data.SHEIN_KEY_PWA_17494,
      national_id_tr: data.SHEIN_KEY_PWA_17732,
      identity_type: data.SHEIN_KEY_PWA_17729,
      national_id_num_8: data.SHEIN_KEY_PWA_17697,
      ruc_num_11: data.SHEIN_KEY_PWA_17698,
      state_city_in_other_country: data.SHEIN_KEY_PWA_18082,
      search_exceeded: data.SHEIN_KEY_PWA_20857,
      national_id_qa: data.SHEIN_KEY_PWA_18346,
      select_certificate_type: data.SHEIN_KEY_PWA_18570,
      cetificate_national_id_num: data.SHEIN_KEY_PWA_18538,
      cetificate_passport_num: data.SHEIN_KEY_PWA_18539,
      city_number: data.SHEIN_KEY_PWA_18419,
      district_br: data.SHEIN_KEY_PWA_18333,
      refilling_existing: data.SHEIN_KEY_PWA_14580,
      choose: data.SHEIN_KEY_PWA_18738,
      email: data.SHEIN_KEY_PWA_15331,
      email_empty: data.SHEIN_KEY_PWA_15589,
      TW_KEY_0001: data.SHEIN_KEY_PWA_19575,
      TW_KEY_0002: data.SHEIN_KEY_PWA_19660,
      TW_KEY_0003: data.SHEIN_KEY_PWA_19577,
      placeholder_national_id_196: data.SHEIN_KEY_PWA_19718,
      tips_national_id_196: data.SHEIN_KEY_PWA_19719,
      tips_national_id_165: data.SHEIN_KEY_PWA_25132,
      tax_number_placeholder_63: data.SHEIN_KEY_PWA_19902,
      divider_text_197: data.SHEIN_KEY_PWA_19893,
      tip_passport_number_197: data.SHEIN_KEY_PWA_19896,
      tip_tax_number_197: data.SHEIN_KEY_PWA_20279,
      error_tip_tax_number_197: data.SHEIN_KEY_PWA_19895,
      error_tip_passport_number_197: data.SHEIN_KEY_PWA_19897
    },
    passport: {
      SHEIN_KEY_PWA_18539: data.SHEIN_KEY_PWA_18539,
      SHEIN_KEY_PWA_17001: data.SHEIN_KEY_PWA_17001,
      SHEIN_KEY_PWA_16993: data.SHEIN_KEY_PWA_16993,
      SHEIN_KEY_PWA_16994: data.SHEIN_KEY_PWA_16994,
      SHEIN_KEY_PWA_16995: data.SHEIN_KEY_PWA_16995,
      SHEIN_KEY_PWA_16996: data.SHEIN_KEY_PWA_16996,
      SHEIN_KEY_PWA_16997: data.SHEIN_KEY_PWA_16997,
      SHEIN_KEY_PWA_16998: data.SHEIN_KEY_PWA_16998,
      SHEIN_KEY_PWA_16999: data.SHEIN_KEY_PWA_16999,
      SHEIN_KEY_PWA_17000: data.SHEIN_KEY_PWA_17000,
      SHEIN_KEY_PWA_17039: data.SHEIN_KEY_PWA_17039,
      SHEIN_KEY_PWA_17006: data.SHEIN_KEY_PWA_17006,
      SHEIN_KEY_PWA_17007: data.SHEIN_KEY_PWA_17007,
      SHEIN_KEY_PWA_15146: data.SHEIN_KEY_PWA_15146,
      SHEIN_KEY_PWA_15498: data.SHEIN_KEY_PWA_15498,
      SHEIN_KEY_PWA_15291: data.SHEIN_KEY_PWA_15291,
      SHEIN_KEY_PWA_15292: data.SHEIN_KEY_PWA_15292,
      SHEIN_KEY_PWA_15293: data.SHEIN_KEY_PWA_15293,
      SHEIN_KEY_PWA_15234: data.SHEIN_KEY_PWA_15234,
      SHEIN_KEY_PWA_15574: data.SHEIN_KEY_PWA_15574,
      SHEIN_KEY_PWA_16439: data.SHEIN_KEY_PWA_16439,
      SHEIN_KEY_PWA_17353: data.SHEIN_KEY_PWA_17353
    }
  }
}
